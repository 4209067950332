
@-webkit-keyframes jump {

    0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  
    40%  {-webkit-transform: translate3d(0,50%,0);transform: translate3d(0,50%,0);}
  
    100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  
  }
  
  @keyframes jump {
  
    0%   {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  
    40%  {-webkit-transform: translate3d(0,50%,0);transform: translate3d(0,50%,0);}
  
    100% {-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
  
  }
  
  
  
  @-webkit-keyframes rotated {
  
    0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  
    100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
  
  }
  
  @keyframes rotated {
  
    0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  
    100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
  
  }
  
  
  
  @-webkit-keyframes rotatedHalf {
  
    0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  
    50% {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  
    100%   {-webkit-transform: rotate(0);transform: rotate(0);}
  
  }
  
  @keyframes rotatedHalf {
  
    0%   {-webkit-transform: rotate(0);transform: rotate(0);}
  
    50% {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  
    100%   {-webkit-transform: rotate(0);transform: rotate(0);}
  
  }
  
  
  
  @-webkit-keyframes rotatedHalfTwo {
  
    0% {-webkit-transform: rotate(-90deg);transform: rotate(-90deg);}
  
    100%   {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  
  }
  
  @keyframes rotatedHalfTwo {
  
    0% {-webkit-transform: rotate(-90deg);transform: rotate(-90deg);}
  
    100%   {-webkit-transform: rotate(90deg);transform: rotate(90deg);}
  
  }
  
  
  @-webkit-keyframes scale-upOne {
  
    0%   {-webkit-transform: scale(1);transform: scale(1);}
  
    100% {-webkit-transform: scale(0.2);transform: scale(0.2);}
  
  }
  
  @keyframes scale-upOne {
  
    0%   {-webkit-transform: scale(1);transform: scale(1);}
  
    100% {-webkit-transform: scale(0.2);transform: scale(0.2);}
  
  }
  
  
  
  @-webkit-keyframes scale-right {
  
    0%  {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
  
    50% {-webkit-transform: translateX(50%);transform: translateX(50%);}
  
    100% {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
  
  }
  
  @keyframes scale-right {
  
    0%  {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
  
    50% {-webkit-transform: translateX(50%);transform: translateX(50%);}
  
    100% {-webkit-transform: translateX(-50%);transform: translateX(-50%);}
  
  }
  
  
  
  @-webkit-keyframes fade-in {
  
    0%   {opacity: 0.7;}
  
    40%  {opacity: 1;}
  
    100% {opacity: 0.7;}
  
  }
  
  @keyframes fade-in {
  
    0%   {opacity: 0.7;}
  
    40%  {opacity: 1;}
  
    100% {opacity: 0.7;}
  
  }
  
  @keyframes hvr-ripple-out {
  
    0% {
  
      top: 0;
  
      right: 0;
  
      bottom: 0;
  
      left: 0;
  
    }
  
    100% {
  
      top: -6px;
  
      right: -6px;
  
      bottom: -6px;
  
      left: -6px;
  
    }
  
  }
  
  @keyframes hvr-ripple-out-two {
  
    0% {
  
      top: 0;
  
      right: 0;
  
      bottom: 0;
  
      left: 0;
  
    }
  
    100% {
  
      top: -18px;
  
      right: -18px;
  
      bottom: -18px;
  
      left: -18px;
  
      opacity: 0;
  
    }
  
  }
  
  
  
  @-webkit-keyframes scale-up-one {
  
    0%   {-webkit-transform: scale(1);transform: scale(1);}
  
    40%  {-webkit-transform: scale(0.5);transform: scale(0.5);}
  
    100% {-webkit-transform: scale(1);transform: scale(1);}
  
  }
  
  @keyframes scale-up-one {
  
    0%   {-webkit-transform: scale(1);transform: scale(1);}
  
    40%  {-webkit-transform: scale(0.5);transform: scale(0.5);}
  
    100% {-webkit-transform: scale(1);transform: scale(1);}
  
  }
  
  @-webkit-keyframes scale-up-two {
  
    0%   {-webkit-transform: scale(0.5);transform: scale(0.5);}
  
    40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
  
    100% {-webkit-transform: scale(0.5);transform: scale(0.5);}
  
  }
  
  @keyframes scale-up-two {
  
    0%   {-webkit-transform: scale(0.5);transform: scale(0.5);}
  
    40%  {-webkit-transform: scale(0.8);transform: scale(0.8);}
  
    100% {-webkit-transform: scale(0.5);transform: scale(0.5);}
  
  }
  
  @-webkit-keyframes scale-up-three {
  
    0%   {-webkit-transform: scale(0.7);transform: scale(0.7);}
  
    40%  {-webkit-transform: scale(0.4);transform: scale(0.4);}
  
    100% {-webkit-transform: scale(0.7);transform: scale(0.7);}
  
  }
  
  @keyframes scale-up-three {
  
    0%   {-webkit-transform: scale(0.7);transform: scale(0.7);}
  
    40%  {-webkit-transform: scale(0.4);transform: scale(0.4);}
  
    100% {-webkit-transform: scale(0.7);transform: scale(0.7);}
  
  }
  
  
  
  
  
  @keyframes animationFramesOne{
  
    0% {
  
      transform:  translate(0px,0px)  rotate(0deg) ;
  
      -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  
      -moz-transform:  translate(0px,0px)  rotate(0deg) ;
  
      -ms-transform:  translate(0px,0px)  rotate(0deg) ;
  
      -o-transform:  translate(0px,0px)  rotate(0deg) ;
  
  }
  
    20% {
  
      transform:  translate(73px,-1px)  rotate(36deg) ;
  
      -webkit-transform:  translate(73px,-1px)  rotate(36deg) ;
  
      -moz-transform:  translate(73px,-1px)  rotate(36deg) ;
  
      -ms-transform:  translate(73px,-1px)  rotate(36deg) ;
  
      -o-transform:  translate(73px,-1px)  rotate(36deg) ;
  
  }
  
    40% {
  
      transform:  translate(141px,-20px)  rotate(72deg) ;
  
      -webkit-transform:  translate(141px,-20px)  rotate(72deg) ;
  
      -moz-transform:  translate(141px,-20px)  rotate(72deg) ;
  
      -ms-transform:  translate(141px,-20px)  rotate(72deg) ;
  
      -o-transform:  translate(141px,-20px)  rotate(72deg) ;
  
  }
  
    60% {
  
      transform:  translate(83px,-60px)  rotate(108deg) ;
  
      -webkit-transform:  translate(83px,-60px)  rotate(108deg) ;
  
      -moz-transform:  translate(83px,-60px)  rotate(108deg) ;
  
      -ms-transform:  translate(83px,-60px)  rotate(108deg) ;
  
      -o-transform:  translate(83px,-60px)  rotate(108deg) ;
  
  }
  
    80% {
  
      transform:  translate(-40px,72px)  rotate(144deg) ;
  
      -webkit-transform:  translate(-40px,72px)  rotate(144deg) ;
  
      -moz-transform:  translate(-40px,72px)  rotate(144deg) ;
  
      -ms-transform:  translate(-40px,72px)  rotate(144deg) ;
  
      -o-transform:  translate(-40px,72px)  rotate(144deg) ;
  
  }
  
    100% {
  
      transform:  translate(0px,0px)  rotate(0deg) ;
  
      -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  
      -moz-transform:  translate(0px,0px)  rotate(0deg) ;
  
      -ms-transform:  translate(0px,0px)  rotate(0deg) ;
  
      -o-transform:  translate(0px,0px)  rotate(0deg) ;
  
  }
  
  }
  
  
  
  
  
  @-webkit-keyframes animationFramesOne {
  
    0% {
  
      -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  
    }
  
    20% {
  
      -webkit-transform:  translate(73px,-1px)  rotate(36deg) ;
  
    }
  
    40% {
  
      -webkit-transform:  translate(141px,72px)  rotate(72deg) ;
  
    }
  
    60% {
  
      -webkit-transform:  translate(83px,122px)  rotate(108deg) ;
  
    }
  
    80% {
  
      -webkit-transform:  translate(-40px,72px)  rotate(144deg) ;
  
    }
  
    100% {
  
      -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
  
    }
  
  }
  
  
  
  @keyframes animationFramesTwo{
  
    0% {
  
      transform:  translate(0px,0px)  rotate(0deg) scale(1) ;
  
    }
  
    20% {
  
      transform:  translate(73px,-1px)  rotate(36deg) scale(0.9);
  
    }
  
    40% {
  
      transform:  translate(141px,72px)  rotate(72deg) scale(1);
  
    }
  
    60% {
  
      transform:  translate(83px,122px)  rotate(108deg) scale(1.2);
  
    }
  
    80% {
  
      transform:  translate(-40px,72px)  rotate(144deg) scale(1.1);
  
    }
  
    100% {
  
      transform:  translate(0px,0px)  rotate(0deg) scale(1);
  
    }
  
  }
  
  
  
  
  
  @-webkit-keyframes animationFramesTwo {
  
    0% {
  
      -webkit-transform:  translate(0px,0px)  rotate(0deg) scale(1);
  
    }
  
    20% {
  
      -webkit-transform:  translate(73px,-1px)  rotate(36deg) scale(0.9);
  
    }
  
    40% {
  
      -webkit-transform:  translate(141px,72px)  rotate(72deg) scale(1);
  
    }
  
    60% {
  
      -webkit-transform:  translate(83px,122px)  rotate(108deg) scale(1.2);
  
    }
  
    80% {
  
      -webkit-transform:  translate(-40px,72px)  rotate(144deg) scale(1.1);
  
    }
  
    100% {
  
      -webkit-transform:  translate(0px,0px)  rotate(0deg) scale(1);
  
    }
  
  }
  
  
  
  
  
  @keyframes animationFramesThree{
  
    0% {
  
      transform:  translate(165px,-30px)  ;
  
      -webkit-transform:  translate(165px,-30px)  ;
  
      -moz-transform:  translate(165px,-30px)  ;
  
      -ms-transform:  translate(165px,-30px)  ;
  
      -o-transform:  translate(165px,-30px)  ;
  
  }
  
    100% {
  
      transform:  translate(-60px,80px)  ;
  
      -webkit-transform:  translate(-60px,80px)  ;
  
      -moz-transform:  translate(-60px,80px)  ;
  
      -ms-transform:  translate(-60px,80px)  ;
  
      -o-transform:  translate(-60px,80px)  ;
  
  }
  
  }
  
  
  
  
  
  @-webkit-keyframes animationFramesThree{
  
    0% {
  
      transform:  translate(165px,-30px)  ;
  
      -webkit-transform:  translate(165px,-30px)  ;
  
      -moz-transform:  translate(165px,-30px)  ;
  
      -ms-transform:  translate(165px,-30px)  ;
  
      -o-transform:  translate(165px,-30px)  ;
  
  }
  
    100% {
  
      transform:  translate(-60px,80px)  ;
  
      -webkit-transform:  translate(-60px,80px)  ;
  
      -moz-transform:  translate(-60px,80px)  ;
  
      -ms-transform:  translate(-60px,80px)  ;
  
      -o-transform:  translate(-60px,80px)  ;
  
  }
  
  }
  
  
  
  
  
  @keyframes animationFramesFour{
  
    0% {
  
      transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -webkit-transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -moz-transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -ms-transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -o-transform:  translate(-0px,60px)  rotate(0deg) ;
  
  }
  
    100% {
  
      transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -webkit-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -moz-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -ms-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -o-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
  }
  
  }
  
  
  
  
  
  @-webkit-keyframes animationFramesFour{
  
    0% {
  
      transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -webkit-transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -moz-transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -ms-transform:  translate(-0px,60px)  rotate(0deg) ;
  
      -o-transform:  translate(-0px,60px)  rotate(0deg) ;
  
  }
  
    100% {
  
      transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -webkit-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -moz-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -ms-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
      -o-transform:  translate(-100px,-100px)  rotate(180deg) ;
  
  }
  
  }
  
  
  
  
  
  @keyframes animationFramesFive{
  
    0% {
  
      transform:  translate(0,0)  rotate(0deg) ;
  
      -webkit-transform:  translate(0,0)  rotate(0deg) ;
  
      -moz-transform:  translate(0,0)  rotate(0deg) ;
  
      -ms-transform:  translate(0,0)  rotate(0deg) ;
  
      -o-transform:  translate(0,0)  rotate(0deg) ;
  
  }
  
    21% {
  
      transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -webkit-transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -moz-transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -ms-transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -o-transform:  translate(4px,-20px)  rotate(38deg) ;
  
  }
  
    41% {
  
      transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -webkit-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -moz-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -ms-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -o-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
  }
  
    60% {
  
      transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -webkit-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -moz-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -ms-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -o-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
  }
  
    80% {
  
      transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -webkit-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -moz-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -ms-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -o-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
  }
  
    100% {
  
      transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -webkit-transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -moz-transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -ms-transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -o-transform:  translate(-1px,0px)  rotate(180deg) ;
  
  }
  
  }
  
  

  @-webkit-keyframes animationFramesFive{
  
    0% {
  
      transform:  translate(0,0)  rotate(0deg) ;
  
      -webkit-transform:  translate(0,0)  rotate(0deg) ;
  
      -moz-transform:  translate(0,0)  rotate(0deg) ;
  
      -ms-transform:  translate(0,0)  rotate(0deg) ;
  
      -o-transform:  translate(0,0)  rotate(0deg) ;
  
  }
  
    21% {
  
      transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -webkit-transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -moz-transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -ms-transform:  translate(4px,-20px)  rotate(38deg) ;
  
      -o-transform:  translate(4px,-20px)  rotate(38deg) ;
  
  }
  
    41% {
  
      transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -webkit-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -moz-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -ms-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
      -o-transform:  translate(-50px,-60px)  rotate(74deg) ;
  
  }
  
    60% {
  
      transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -webkit-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -moz-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -ms-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
      -o-transform:  translate(-20px,-30px)  rotate(108deg) ;
  
  }
  
    80% {
  
      transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -webkit-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -moz-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -ms-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
      -o-transform:  translate(-195px,-49px)  rotate(144deg) ;
  
  }
  
    100% {
  
      transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -webkit-transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -moz-transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -ms-transform:  translate(-1px,0px)  rotate(180deg) ;
  
      -o-transform:  translate(-1px,0px)  rotate(180deg) ;
  
  }
  
  }
  

@keyframes spin {
	0% {
		transform:rotate(0deg);
	}
	100% {
		transform:rotate(360deg);
	}
}
@-webkit-keyframes zoominup {
	0% {
		transform: scale(1.1);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	} 
}
@-webkit-keyframes updown {
	0% {
		transform: translateY(-10px);
		-webkit-transform: translateY(-10px);
	  	-moz-transform: translateY(-10px);
	  	-ms-transform: translateY(-10px);
	  	-o-transform: translateY(-10px);
	}
	50% {
		transform: translateY(-5px);
		-webkit-transform: translateY(-5px);
	  	-moz-transform: translateY(-5px);
	  	-ms-transform: translateY(-5px);
	  	-o-transform: translateY(-5px);
	}
	100% {
		transform: translateY(-10px);
		-webkit-transform: translateY(-10px);
	  	-moz-transform: translateY(-10px);
	  	-ms-transform: translateY(-10px);
	  	-o-transform: translateY(-10px);
	} 
}
@-webkit-keyframes updown-2 {
	0% {
		transform: translateY(-15px);
		-webkit-transform: translateY(-15px);
	  	-moz-transform: translateY(-15px);
	  	-ms-transform: translateY(-15px);
	  	-o-transform: translateY(-15px);
	}
	50% {
		transform: translateY(-5px);
		-webkit-transform: translateY(-5px);
	  	-moz-transform: translateY(-5px);
	  	-ms-transform: translateY(-5px);
	  	-o-transform: translateY(-5px);
	}
	100% {
		transform: translateY(-15px);
		-webkit-transform: translateY(-15px);
	  	-moz-transform: translateY(-15px);
	  	-ms-transform: translateY(-15px);
	  	-o-transform: translateY(-15px);
	} 
}

@keyframes ltr {
    0% {
      width: 0; }
    15% {
      width: 95%; }
    85% {
      opacity: 1; }
    90% {
      width: 95%;
      opacity: 0; }
    to {
      width: 0;
      opacity: 0; } 
}


/*circleAnimation*/
@-webkit-keyframes circleAnimation {
  0%,
    100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg); }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    -webkit-transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg); }
  50% {
    -webkit-transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    transform: translate3d(0, 0, 0) rotateZ(0.01deg); }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    -webkit-transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg); } }


    @-webkit-keyframes icon-bounce {
      0%, 100%, 20%, 50%, 80% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      40% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
      }
      60% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
      }
    }
    @keyframes icon-bounce {
      0%, 100%, 20%, 50%, 80% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
      40% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
      }
      60% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
      }
    }
    
    @keyframes lr-animation {
      0% {
        @include transform(translateX(40px));
      }
      100% {
        @include transform(translateX(0));
      }
    }
    @keyframes tb-animation {
      0% {
        @include transform(translateY(30px));
      }
      100% {
        @include transform(translateY(0));
      }
    }


    @keyframes xb_up_down {
      0% {
       transform:translateY(0)
      }
      100% {
       transform:translateY(-20px)
      }
     }
     .slide-up-down {
      animation:xb_up_down 1s ease infinite alternate
     }

    @keyframes xb_ltr {
      0% {
       transform:translateY(0)
      }
      100% {
       transform:translateX(-20px)
      }
     }
     .slide-ltr {
      animation:xb_ltr 1s ease infinite alternate
     }


     @-webkit-keyframes spin {
      0% {
        transform: rotate(0deg); 
      }
      100% {
        transform: rotate(360deg); 
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg); 
      }
      100% {
        transform: rotate(360deg); 
      }
    }
    
    @-webkit-keyframes zoom {
      0% {
        @include transform(scale(1));
      }
      50% {
        @include transform(scale(1.05));
      }
      100% {
        @include transform(scale(1.10));
      }
    }
    @keyframes zoom {
      0% {
        @include transform(scale(1.10));
      }
      50% {
        @include transform(scale(1.05));
      }
      100% {
        @include transform(scale(1));
      }
    }
    
    @-webkit-keyframes shake {
      0% {
        @include transform(rotate(7deg));
      }
      50% {
        @include transform(rotate(0deg));
      }
      100% {
        @include transform(rotate(7deg));
      }
    }
    @keyframes shake {
      0% {
        @include transform(rotate(7deg));
      }
      50% {
        @include transform(rotate(0deg));
      }
      100% {
        @include transform(rotate(7deg));
      }
    }
    
    @-webkit-keyframes down {
      0% {
        @include transform(translateY(5px));
      }
      50% {
        @include transform(translateY(0px));
      }
      100% {
        @include transform(translateY(5px));
      }
    }
    @keyframes down {
      0% {
        @include transform(translateY(5px));
      }
      50% {
        @include transform(translateY(0px));
      }
      100% {
        @include transform(translateY(5px));
      }
    }
    @keyframes outer-ripple {
      0% {
        transform: scale(1);
        filter: alpha(opacity=50);
        opacity: 0.5;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        -webkit-filter: alpha(opacity=50);
      }
      80% {
        transform: scale(1.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
      }
      100% {
        transform: scale(2.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(2.5);
        -moz-transform: scale(2.5);
        -ms-transform: scale(2.5);
        -o-transform: scale(2.5);
      }
    }
    @-webkit-keyframes outer-ripple {
      0% {
        transform: scale(1);
        filter: alpha(opacity=50);
        opacity: 0.5;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
      }
      80% {
        transform: scale(2.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(2.5);
        -moz-transform: scale(2.5);
        -ms-transform: scale(2.5);
        -o-transform: scale(2.5);
      }
      100% {
        transform: scale(3.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(3.5);
        -moz-transform: scale(3.5);
        -ms-transform: scale(3.5);
        -o-transform: scale(3.5);
      }
    }
    @-moz-keyframes outer-ripple {
      0% {
        transform: scale(1);
        filter: alpha(opacity=50);
        opacity: 0.5;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
      }
      80% {
        transform: scale(2.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(2.5);
        -moz-transform: scale(2.5);
        -ms-transform: scale(2.5);
        -o-transform: scale(2.5);
      }
      100% {
        transform: scale(3.5);
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transform: scale(3.5);
        -moz-transform: scale(3.5);
        -ms-transform: scale(3.5);
        -o-transform: scale(3.5);
      }
    }


   

    @keyframes blink {
      from, to {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
    
    @-moz-keyframes blink {
      from, to {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
    
    @-webkit-keyframes blink {
      from, to {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
    
    @-ms-keyframes blink {
      from, to {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
    
    @-o-keyframes blink {
      from, to {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  
@keyframes pulse-border {
  0% {
   transform:scale(1);
   opacity:.67
  }
  100% {
   transform:scale(2.2);
   opacity:0
  }
 }
 @-webkit-keyframes pulse-border {
  0% {
   transform:scale(1);
   opacity:.67
  }
  100% {
   transform:scale(2.2);
   opacity:0
  }
 }
 @-webkit-keyframes tada {
  0% {
    -webkit-transform:scale(1);
    transform:scale(1)
  }
  10%,
  20% {
    -webkit-transform:scale(.9)rotate(-5deg);
    transform:scale(.9)rotate(-5deg)
  }
  50%,
  50%,
  70%,
  90% {
    -webkit-transform:scale(1.1)rotate(5deg);
    transform:scale(1.1)rotate(5deg)
  }
  40%,
  60%,
  80% {
    -webkit-transform:scale(1.1)rotate(-5deg);
    transform:scale(1.1)rotate(-5deg)
  }
  100% {
    -webkit-transform:scale(1)rotate(0);
    transform:scale(1)rotate(0)
  }
}
@keyframes tada {
  0% {
    -webkit-transform:scale(1);
    -ms-transform:scale(1);
    transform:scale(1)
  }
  10%,
  20% {
    -webkit-transform:scale(.9)rotate(-5deg);
    -ms-transform:scale(.9)rotate(-5deg);
    transform:scale(.9)rotate(-5deg)
  }
  50%,
  50%,
  70%,
  90% {
    -webkit-transform:scale(1.1)rotate(5deg);
    -ms-transform:scale(1.1)rotate(5deg);
    transform:scale(1.1)rotate(5deg)
  }
  40%,
  60%,
  80% {
    -webkit-transform:scale(1.1)rotate(-5deg);
    -ms-transform:scale(1.1)rotate(-5deg);
    transform:scale(1.1)rotate(-5deg)
  }
  100% {
    -webkit-transform:scale(1)rotate(0);
    -ms-transform:scale(1)rotate(0);
    transform:scale(1)rotate(0)
  }
}
.wow.skewIn.animated {
  -webkit-animation-name: xbSkewIn;
  animation-name: xbSkewIn;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-timing-function: cubic-bezier(.67,.04,.3,.91);
  animation-timing-function: cubic-bezier(.67,.04,.3,.91);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

@-webkit-keyframes xbSkewIn {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
@keyframes xbSkewIn {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

    .skewInImg {
      clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
      display: inline-block;
      position: relative;
      overflow: hidden;
      transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
      img {
        transform-origin: 50% 50%;
        transition: 1.3s cubic-bezier(0.5, 0.5, 0, 1);
        max-width: 100%;
        height: auto;
        transform: scale(1.5) translate(100px, 0px);
      }
      &.animated {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        img {
          transform: scale(1) translate(0px, 0px);
        }
      }
    }
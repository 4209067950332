/*----------------------------------------*/
/*  03. globel
/*----------------------------------------*/
@media (min-width: 1024px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1320px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
.demo-studient-visa {
	font-family: var(--font-inter);
	letter-spacing: -0.16px;
	color: #555;
	font-weight: 400;
}

.demo-travel-agency {
	background-color: #F0F2F6;
	position: relative;
	z-index: 1;
}

.gradient-bg {
	background-image: linear-gradient(90deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100%);
	background-image: -moz-linear-gradient(90deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100%);
	background-image: -ms-linear-gradient(90deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100%);
	background-image: -o-linear-gradient(90deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100%);
	background-image: -webkit-linear-gradient(90deg, var(--gradient-color-from) 0%, var(--gradient-color-to) 100%);
}

// tagcloud
.tagcloud, .tags-links {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	align-items: center;
    margin: -10px;
    a {
        display: block;
        color: #787B84;
  		border: 1px solid #EDF3F5;
        min-height: 36px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        text-transform: capitalize;
        text-decoration: none;
        font-weight: 500;
        padding: 2px 17px 5px;
        margin: 7px;
        position: relative;
		@include border-radius(3px);
		&:hover {
			color: var(--color-white);
			background-color: var(--color-primary);
			border-color: var(--color-primary);
		}
    }
}

.body-overlay {
	background-color: #000000;
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 1010;
	left: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s linear 0s;
	transition: all 600ms ease;
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
}
.body-overlay.active {
	opacity: .5;
	visibility: visible;
}
// post tags
.post-tags {
    li {
        font-size: 15px;
        text-transform: capitalize;
        position: relative;
        &:not(:first-child, :last-child) {
            margin-right: 11px;
            padding-right: 15px;
            &::before {
                position: absolute;
                top: 50%;
                right: 0;
                @include transform(translateY(-50%));
                width: 5px;
                height: 5px;
                background-color: var(--color-primary);
                @include border-radius(50%);
                content: "";
            }
        }
        span {
            display: inline-block;
            background-color: var(--color-primary);
            padding: 0px 10px;
            line-height: 25px;
            color: var(--color-white);
            @include border-radius(3px);
            margin-right: 12px;
        }
        a {
            color: var(--color-black);
            &:hover {
                color: var(--color-black);
            }
        }
    }
}

.mfp-zoom-in .mfp-content {
	opacity: 0;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transform: scale(0.9);
	-ms-transform: scale(0.9);
	transform: scale(0.9);
}
.mfp-zoom-in.mfp-ready .mfp-content {
	opacity: 1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

body .mfp-wrap .mfp-container .mfp-content .mfp-close {
	padding: 0;
	right: 0;
	text-align: center;
	top: -36px;
	width: 36px;
	height: 36px;
	-webkit-border-radius: 0;
	-khtml-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
	background: var(--color-white);
	cursor: pointer;
	opacity: 1;
	font-size: 0;
	border: 9px solid transparent;
	position: absolute;
}
body .mfp-wrap .mfp-container .mfp-content .mfp-figure .mfp-close {
	top: 4px;
}
body .mfp-wrap .mfp-container .mfp-content .mfp-close::before, body .mfp-wrap .mfp-container .mfp-content .mfp-close::after {
	content: '';
	position: absolute;
	height: 2px;
	width: 100%;
	top: 50%;
	left: 0;
	margin-top: -1px;
	transform-origin: 50% 50%;
	-webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
	-khtml-transition: all .25s cubic-bezier(.645,.045,.355,1);
	-moz-transition: all .25s cubic-bezier(.645,.045,.355,1);
	-ms-transition: all .25s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .25s cubic-bezier(.645,.045,.355,1);
	transition: all .25s cubic-bezier(.645,.045,.355,1);
	background-color: #222;
}
body .mfp-wrap .mfp-container .mfp-content .mfp-close::before {
	-webkit-transform: rotate(45deg);
	-khtml-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}
body .mfp-wrap .mfp-container .mfp-content .mfp-close::after {
	-webkit-transform: rotate(-45deg);
	-khtml-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::before, body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::after {
	-webkit-transform: rotate(0);
	-khtml-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0);
}
body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::before, body .mfp-wrap .mfp-container .mfp-content .mfp-close:hover::after {
	-webkit-transform: rotate(0);
	-khtml-transform: rotate(0);
	-moz-transform: rotate(0);
	-ms-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0);
}
.mfp-iframe-holder .mfp-content {
	max-width: 1170px;
}
// swiper
.xb-carousel-inner {
	margin: -30px;
}
.testimonial.travel {
	.xb-carousel-inner {
		margin-top: 0px;
		margin-bottom: 20px;
	}	
}
.xb-swiper-sliders {
	position: relative;
}
.xb-swiper-container {
	overflow: hidden;
	padding: 30px;
}
.xb-swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	-o-transition-property: transform;
	transition-property: transform; 
	transition-property: transform,-webkit-transform;
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}
.xb-swiper-slide:not(.swiper-slide-visible) {
	opacity: 0;
}
.xb-swiper-slide{
	-webkit-transition: opacity 0.25s cubic-bezier(.71,.02,.31,1);
	-khtml-transition: opacity 0.25s cubic-bezier(.71,.02,.31,1);
	-moz-transition: opacity 0.25s cubic-bezier(.71,.02,.31,1);
	-ms-transition: opacity 0.25s cubic-bezier(.71,.02,.31,1);
	-o-transition: opacity 0.25s cubic-bezier(.71,.02,.31,1);
	transition: opacity 0.25s cubic-bezier(.71,.02,.31,1);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: flex;
	align-items: center;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
}
.border-bottom {
	border-bottom: 1px solid #F3F3F3;
}
.z-index-2 {
	z-index: 2;
	position: relative;
}
.xb-overlay {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.xb-overlay-link {
	z-index: 1;
}
.text-12 {
	font-size: 12px;
}
.letter-spacing-0 {
	letter-spacing: 0;
}
.margin-none-md {
	@include respond(md) {
		margin-bottom: 0;
	}
}
.mb-30-xs {
	@include respond(xs) {
		margin-bottom: 30px;
	}
}
// sidebar widget
.sidebar-widget {
	.widget {
		background-color: #EDF3F5;
		padding: 30px 40px;
		@include border-radius(20px);
		margin-bottom: 50px;
		@include respond(xs) {
			padding: 30px 20px;
		}
		h3 {
			font-size: 24px;
			color: #0F172A;
			margin-bottom: 25px;
		}
	}
	.widget-category {
		li {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
			a {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 18px;
				color: #787B84;
				background-color: var(--color-white);
				padding: 17px 20px;
				@include border-radius(10px);
				&:hover,
				&.active {
					background-color: #4e2b76;
					color: var(--color-white);
				}
				span {
					width: 30px;
					height: 30px;
					background-color: #4e2b76;
					border: 1px solid #EDF3F5;
					display: inline-block;
					@include center;
					@include border-radius(50%);
				}
			}
		}
		&.country-widget {
			li {
				a span {
					background: none;
					width: 34px;
					height: 34px;
					border: 0;
					&.plus {
						background-color: #EDF3F5;
						color: #0F172A;
						font-size: 14px;
					}
				}
			}
		}
	}
	.widget-download {
		margin: 0 -10px;
		li {
			width: 50%;
			padding: 0 10px;
			a {

				background-color: var(--color-white);
				display: inline-block;
				text-align: center;
				padding: 20px 10px;
				padding-bottom: 20px;
				padding-bottom: 0;
				width: 100%;
				@include border-radius(10px);
			}
		}
		.xb-item {
			&--icon {
				width: 50px;
				height: 50px;
				@include center;
				background-color: var(--color-primary);
				margin: 0 auto 15px;
				@include border-radius(50%);
			}
			&--title {
				font-size: 16px;
				line-height: 22px;
				letter-spacing: -0.16px;
				margin-bottom: 15px;
			}
			&--size {
				color: #787B84;
				font-family: var(--font-heading);
				font-size: 14px;
				font-weight: 500;
				line-height: 22px;
				letter-spacing: -0.14px;
				border-top: 1px solid #EDF3F5;
				margin: 0 -10px;
				padding: 4px;
			}
 		}
	}
}
.widget-banner {
	&.widget {
		padding: 50px 40px;
	}
	h4 {
		font-size: 28px;
		line-height: 40px;
		margin-bottom: 40px;
	}
}
.single-content {
	h3 {
		font-weight: 700;
		margin-bottom: 25px;
		font-size: 32px;
	}
	h4 {
		font-size: 24px;
		margin-bottom: 30px;
		letter-spacing: -0.72px;
	}
	p {
		font-size: 20px;
		font-weight: 500;
		line-height: 32px;
		color: #787B84;
		margin-bottom: 30px;
	}
	&__feature {
		margin: 0 -15px;
		margin-bottom: 50px;
	}
}
.single-content-feature {
	width: 50%;
	@include respond(xs) {
		width: 100%;
	}
	.xb-item {
		&--inner {
			background-color: var(--color-white);
			border: 1px solid #EDF3F5;
			margin: 15px;
			@include border-radius(10px);
			padding: 30px 25px;
			position: relative;
			@include transition(.3s);
			&.color-2 {
				&::before {
					background-color: #1496F8;
				}
				.xb-item--icon {
					background-color: rgba(20, 149, 248, 0.1);
				}
			}
			&.color-3 {
				&::before {
					background-color: #00CC99;
				}
				.xb-item--icon {
					background-color: rgba(0, 204, 153, 0.1);
				}
			}
			&.color-4 {
				&::before {
					background-color: #FFBD0F;
				}
				.xb-item--icon {
					background-color: rgba(255, 187, 15, 0.1);
				}
			}
			&::before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 4px;
				height: 47px;
				background-color: #FE6C3F;
				@include transform(translateY(-50%));
				content: "";
			}
			&:hover {
				box-shadow: 0px 21px 32px rgba(206, 220, 227, 0.23);
			}
		}
		&--icon {
			width: 47px;
			height: 47px;
			@include border-radius(50%);
			background-color: rgba(254, 108, 63, 0.1);
			@include center;
			margin-right: 15px;
		}
		&--title {
			font-size: 20px;
			font-weight: 600;
			line-height: 30px;
			letter-spacing: -0.6px;
			margin-bottom: 0;
		}
	}
}
.single-content-list {
	@include respond(md) {
		padding-left: 0;
	}
	li {
		font-size: 20px;
		font-family: var(--font-heading);
		font-weight: 500;
		display: flex;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 17px;
		}
		img {
			margin-right: 10px;
		}
	}
}
.z-1 {
	z-index: 1;
}

.xb-strock-text {
	font-size: 303px;
	font-weight: 700;
	letter-spacing: -9.09px;
	font-family: var(--font-dancing);
	color: rgba(0, 0, 0, 0.02);
	z-index: -1;
	left: 0;
	right: 0;
	width: auto;
	text-align: center;
	position: absolute;
	bottom: 2%;
	@include respond(lg) {
		font-size: 160px;
	}
	@include respond(xs) {
		font-size: 40px;
    	letter-spacing: -2px;
	}
}
.xb-star-rating {
	padding: 0;
	margin: 0;
	list-style: none;
	line-height: 0;
}

.xb-star-rating li {
	display: inline-flex;
	font-size: 12px;
	line-height: 1.5em;
	color: #ffcc00;
	margin: 0;
}
/*----------------------------------------*/
/*  12. faq
/*----------------------------------------*/
.xb-faq-content {
    .faq-img {
        margin-left: -50px;

        @include respond(md) {
            margin-left: 0;
        }
    }
}

.xb-faq {
    padding-left: 55px;

    @include respond(lg) {
        padding-left: 20px;
    }
}

.accordion_box {
    position: relative;
}

.accordion-body {
    padding: 0;
}

.accordion_box .block {
    position: relative;
    overflow: hidden;
    border: 1px solid #EDF3F5;
    @include border-radius(20px);
    margin-bottom: 20px;
    z-index: 1;

    &::before {
        position: absolute;
        width: 52px;
        height: 101px;
        @include border-radius(28px);
        background-color: #F4F8FA;
        content: "";
        top: -22px;
        right: 4px;
        z-index: -1;
        @include transform(rotate(-134deg));
    }
}

.accordion_box .block:last-child {
    margin-bottom: 0;
}

.accordion_box .block .acc-btn .accordion-button {
    color: var(--color-heading);
    position: relative;
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
    padding: 29px 40px;
    padding-right: 70px;
    @include respond(all 500ms ease);
    background: 0;
    border: 0;
    box-shadow: none;

    .MuiAccordionSummary-content {
        margin: 0;
    }

    &:after {
        display: none;
    }

    p {
        font-size: 24px;
        color: #110F10;

        @include respond(xs) {
            font-size: 18px;
        }

    }

    .arrow {
        position: absolute;
        right: 27px;
        top: 24px;
        width: 42px;
        height: 42px;
        line-height: 26px;
        @include border-radius(50%);
        background-color: #fff;
        box-shadow: 0px 4px 7px rgba(178, 190, 205, 0.29);

        &::before {
            top: 8px;
            left: 15px;
            font-size: 16px;
            color: var(--color-dark);
            content: "\f068";
            font-family: "Font Awesome 5 Pro";
            font-weight: 500;
            position: absolute;
            @include transition(0.6s cubic-bezier(0.165, 0.84, 0.44, 1));
        }
    }

    &.collapsed {
        .arrow::before {
            content: "\f067";
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
        }
    }
}

.accordion_box .block .acc-btn span {
    display: block;
    font-size: 14px;
    color: #B3B3C0;
    font-weight: 600;
    margin-bottom: 16px;
}

.accordion_box .block .content {
    position: relative;
    padding: 0 40px;
    padding-bottom: 35px;

    p {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    ul {
        list-style: none;

        li {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            i {
                margin-right: 10px;
                font-size: 16px;
            }
        }
    }
}

.accordion_box .block .acc_body {
    position: relative;
}

.accordion_box .block .acc_body.current {
    display: block;
}

.xb-faq2 {
    padding: 0;

    .accordion_box {
        .block {
            @include border-radius(0);
            border-style: dashed;
            border-color: #CDCECF;
            margin: 0;
            margin-top: -1px;

            .acc-btn {
                background-color: var(--color-white);

                button {
                    font-size: 24px;
                    color: #110F10;

                    @include respond(xs) {
                        font-size: 18px;
                    }

                    &.collapsed {
                        .arrow {
                            background: #fff;
                        }
                    }

                }

                .arrow {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #F6F6F6;
                    box-shadow: none;
                    top: 31px;
                    right: 38px;
                    z-index: 1;

                    @include respond(xs) {
                        right: 25px;
                    }

                    &::before {
                        top: 0px;
                        left: 10px;
                        font-size: 14px;
                        color: #110F10;
                    }

                    &::after {
                        @include before;
                        background: var(--color-gradient);
                        z-index: -1;
                        @include border-radius(50%);
                        @include transition(.3s);
                        opacity: 0;
                        color: #110F10;
                    }
                }
            }

            button {
                &.collapsed {
                    .acc-btn .arrow {
                        &::before {
                            color: #110F10;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }

            }

            .content {
                padding: 35px 40px;
                padding-right: 100px;
                font-size: 18px;
                line-height: 28px;
                background: #f6f6f6;

                @include respond(xs) {
                    padding: 25px;
                }
            }
        }
    }
}

.faq-shape {
    .shape {
        position: absolute;

        &--1 {
            top: 11%;
            left: 4%;

            @include respond(laptop) {
                top: 5%;
                left: 3%;
            }

            @include respond(lg) {
                top: 4%;
            }
        }

        &--2 {
            right: 3%;
            top: 13%;

            @include respond(laptop) {
                right: 3%;
                top: 7%;
            }
        }
    }
}
/* Navigation css */
.main-menu {
    display: flex;
    align-items: center;
    flex-grow: 1;
    ul {
        @include d-flex;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            position: relative;
            &:not(:last-child) {
                margin-right: 20px;
                @include respond(laptop) {
                    margin-right: 15px;
                }
            }
            .submenu li {
                margin-right: 0;
            }
            a {
                display: inline-block;
                text-decoration: none;
                font-size: 14px;
                font-weight: 600;
                color: var(--color-default);
                padding: 41px 0;
                position: relative;
                line-height: 22px;
                @include transition(all .25s cubic-bezier(.645,.045,.355,1));
            }
            &.menu-item-has-children {
                & > a span::after {
                    content: "+";
                    display: inline-block;
                    padding-left: 18px;
                    font-weight: 500;
                    @include respond(translateY(-1px));
                }
                &:hover > .submenu {
                    opacity: 1;
                    visibility: visible;
                    @include transform(none !important);
                    pointer-events: all;
                }
            }
            &:hover > a,
            &.active > a {
                color: var(--color-dark);
            }
            .submenu {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                position: absolute;
                min-width: 240px;
                top: 100%;
                opacity: 0;
                visibility: hidden;
                background: var(--color-white);
                left: -10px;
                padding: 20px 0;
                @include transition(.3s);
                z-index: 3;
                text-align: left;
                @include transform(translate3d(0,18px,0));
                pointer-events: none;
                box-shadow: 0 0.5rem 1.875rem rgba(0, 0, 0, 0.10);
                li {
                    a {
                        display: block;
                        margin: 0;
                        padding: 7px 30px;
                    }
                    &:hover > a,
                    &.active > a {
                        color: var(--color-dark);
                    }
                }
                ul {
                    left: 100%;
                    top: 0px;
                }
            }
        }
    }
    & > ul > li > a  span {
        position: relative;
        display: inline-block;
        padding: 5px 19px;
        border: 1px solid var(--color-border);
        @include border-radius(5px);
    }
}
/*----------------------------------------*/
/*  28. backtotop
/*----------------------------------------*/
.xb-backtotop {
    right: 30px;
    z-index: 999;
    bottom: 20px;
    position: fixed;
    @include transition(.3s);
    opacity: 0;
    visibility: hidden;
    &.active {
        bottom: 40px;
        opacity: 1;
        visibility: visible;
    }
    .scroll {
        z-index: 1;
        width: 40px;
        height: 40px;
        display: block;
        background-color: var(--color-primary);
        @include center;
        position: relative;
        box-shadow: 0px 4px 7px rgba(178, 190, 205, 0.29);
        color: var(--color-white);
        @include border-radius(5px);
        font-size: 14px;
        &:hover {
            margin-bottom: 4px;
        }
    }
    &.style-2 {
        .scroll {
            background: var(--color-gradient);
        }
    }
}
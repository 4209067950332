/*----------------------------------------*/
/*  29. search
/*----------------------------------------*/
.header-search-form-wrapper {
    position:fixed;
    top:0;
    left:0;
    right: 0;
    width:100%;
    height:400px;
    z-index:9999;
    background-color: #fff;
    transform: translate3d(0,-100%,0);
    transition: transform .85s cubic-bezier(.175,1,.275,1), visibility .8s step-end;
    @include respond(xs) {
        height: 200px;
    }
   }
   .header-search-form-wrapper.open {
    transition: transform .85s cubic-bezier(.175,1,.275,1), visibility .8s step-start;
    transform: translate3d(0,0,0);
   }
   .header-search-form-wrapper .search-form {
    margin:0 auto;
    position:relative;
    text-align:center;
    top:50%;
    left:0;
    -webkit-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    transform:translateY(-50%)
   }
  
   .header-search-form-wrapper .search-form input[type="search"] {
    height:65px;
    line-height:65px;
    font-size:28px;
    background-color:transparent;
    text-align:left;
    color: balck;
    border:none;
    border-bottom: 1px solid #ccc;
    padding-left:0;
    border-radius:0;
    width: 100%;
    color: #000;
    @include respond(xs) {
        height: 45px;
        line-height: 50px;
        font-size: 18px;
    }
   }
 
   

   .header-search-form-wrapper .search-form input[type="search"]::placeholder {
    color:#a4a4a4;
    opacity:1
   }
   .header-search-form-wrapper .search-form input[type="search"]:-ms-input-placeholder {
    color:#a4a4a4
   }
   .header-search-form-wrapper .search-form input[type="search"]::-ms-input-placeholder {
    color:#a4a4a4
   }
   .header-search-form-wrapper .search-submit {
    position:absolute;
    height:50px;
    width:50px;
    bottom: 0;
    right:0;
    background-color:transparent;
    color: #9F9F9F;
    font-size: 24px;
    @include respond(xs) {
        height: 35px;
        font-size: 20px;
    }
   }
   .header-search-form-wrapper .xb-search-close {
        background: #cccccc45;
        width: 54px;
        height: 54px;
        border-width: 18px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        &::before,
        &::after {
            background-color: var(--color-black);

        }
        @include respond(xs) {
            width: 50px;
            height: 50px;
            background-color: transparent;
        }
   }

.header-search-container {
    width: 100%;
    height: 100%;
    max-width: 1170px;
    border: 0;
    padding: 0;
    background: 0 0;
    transform: translate3d(0,calc(100% - 100px),0);
    transition: transform .85s cubic-bezier(.175,1,.275,1);
    margin: auto;
    opacity: 0;
    visibility: hidden;
    @include respond(lg) {
        padding: 0 50px;
    }
    @include respond(xs) {
        padding: 0 15px;
    }
}
   .header-search-form-wrapper.open .header-search-container {
	transform: translate3d(0,0,0);
    opacity: 1;
    visibility: visible;
}

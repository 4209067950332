/* reset css start */
:root {
  scroll-behavior: auto;
}
html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-weight: 500;
  position: relative;
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 26px;
  color: var(--color-default);
  background-color: var(--color-white);
  letter-spacing: -0.2px;

}


img {
  max-width: 100%;
  height: auto;
}

ul {
	margin: 0px;
	padding: 0px;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

button {
  border: none;
  @include transition(.3s);
}

button:focus {
  outline: none;
}
a {
  text-decoration: none;
  @include transition(.3s);
  &:hover {
    color: inherit;
  }
}
select {
	height: 55px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-position: calc(100% - 10px) 50%;
	background-repeat: no-repeat;
	padding-right: 20px;
  background-color: transparent;
  border: 0;
}
input[type="text"], input[type="password"], input[type="email"], input[type="tel"], form select, textarea {
	width: 100%;
	height: 50px;
	border-radius: 0;
	padding: 0;
  border: 0;
	@include transition(.3s);
  color: var(--color-black);
  font-weight: 500;
}
textarea {
  height: 100px;
}
button {
  border: 0;
}
// span
//   display: inline-block
table {
  width: 100%;
}

p, li, span {
  margin-bottom: 0;
}

/* reset css end */

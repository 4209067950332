/*----------------------------------------*/
/*  09. country
/*----------------------------------------*/
.xb-country-nav {
    border: 0;
    padding: 10px;
    background-color: #F4F8FA;
    @include border-radius(10px);
    @include respond(md) {
        justify-content: start;
    }
    .nav-item {
        width: 14.285%;
        @include respond(md) {
            width: 25%;
            margin-top: 10px;
        }
        @include respond(xs) {
            width: 50%;
        }
        .nav-link {
            display: block;
            width: 100%;
            text-align: center;
            border: 0;
            font-size: 18px;
            font-weight: 500;
            color: #8A879F;
            @include border-radius(5px);
            padding: 7px 5px;
            position: relative;
            z-index: 1;
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                width: 2px;
                height: 100%;
                background-color: #E4E6EA;
                content: "";
                @include transition(.3s);
            }
            &::before {
                @include before;
                box-shadow: 0px 4px 4px rgba(198, 213, 221, 0.37);
                background-color: var(--color-white);
                opacity: 0;
                z-index: -1;
                @include border-radius(5px);
                @include transition(.3s);
                left: -2px;
                right: -2px;
            }
            &.active {
                color: var(--color-dark);
                &::before {
                    opacity: 1;
                }
                &::after {
                    opacity: 0;
                }
            }
        }
        &:last-child {
            .nav-link::after {
                display: none;
            }
        }
    }
}
.xb-country {
    margin: -10px;
    .xb-item {
        &--item {
            width: 25%;
            padding: 10px;
            @include respond(md) {
                width: 50%;
            }
        }
        &--inner {
            border: 1px solid #EDF3F5;
            padding: 23px;
            padding-top: 15px;
            @include transition(.3s);
            @include border-radius(20px);
            &:hover {
                box-shadow: 0px 14px 19px 0px rgba(221, 229, 236, 0.42);
            }
        }
        &--flag {
            width: 47px;
            height: 47px;
            @include border-radius(50%);
            overflow: hidden;
            margin-right: 27px;
            margin-top: 8px;
        }
        &--title {
            font-size: 20px;
            letter-spacing: -0.6px;
            margin-top: 8px;
        }
    }
}
.xb-country-slide {
    .xb-swiper-slide {
        justify-content: center;
    }
}
.xb-country2 {
    position: relative;
    margin-top: 5px;
    &::before {
        position: absolute;
        top: 30px;
        left: 0;
        width: 3px;
        height: 46px;
        background-image: url(../../images/shape/c_line.png);
        content: "";
        @include respond(lg) {
            right: -42px;
        }
    }
    &:hover {
        .xb-item {
            &--flag {
                @include transform(translateY(-5px));
            }
        }
    }
    .xb-item {
        &--inner {
            position: relative;
        }
        &--link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &--title {
            margin-top: 20px;
        }
        &--flag {
            max-width: 111px;
            margin: auto;
            @include transition(.3s cubic-bezier(.67,.04,.3,.91));
        }
    }
}

.xb-country3 {
    @include respond(md) {
        width: 50%;
        padding: 10px;
    }
    &__wrap {
        padding: 0 155px;
        @media only screen and (min-width: 1200px) and (max-width: 1300px) {
            padding: 0 30px;
            padding-right: 30px;
            padding-right: 125px;
        }
        @include respond(lg) {
            padding: 0;
            padding-right: 100px;
        }
        @include respond(md) {
            padding: 20px;
        }
        @include respond(xs) {
            padding: 10px;
        }
    }
    .xb-item {
        &--inner {
            background-color: var(--color-white);
            padding: 48px;
            text-align: center;
            filter: drop-shadow(0px 11px 10px rgba(172, 186, 216, 0.07));
            @include border-radius(15px);
            min-width: 216px;
            @include respond(lg) {
                padding: 30px;
                min-width: 180px;
            }
            @include respond(xs) {
                min-width: 160px;
            }
        }
        &--flag {
            width: 70px;
            height: 70px;
            @include border-radius(50%);
            margin: 0 auto 15px;
        }
        &--title {
            font-size: 20px;
        }
    }
    &:nth-child(1) {
        .xb-item--inner {
            @include transform(rotate(-35.415deg) translate(2px, 30px));
        }
    }
    &:nth-child(2) {
        .xb-item--inner {
            @include transform(translateX(40px));
        }
    }
    &:nth-child(3) {
        .xb-item--inner {
            @include transform(rotate(32.489deg) translate(133px, 15px));
        }
    }
    &:nth-child(4) {
        .xb-item--inner {
            @include transform(rotate(-28.427deg) translate(75px, 32px));
        }
    }
    .xb-item--inner {
        @include respond(md) {
            @include transform(none !important);
        }
    }
}

.country-shape {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    z-index: -1;
    background-position: center 150%;
    @include respond(md) {
        display: none;
    }
}

.country-icon-shape {
    .shape {
        position: absolute;
        z-index: -1;
        &--1 {
            top: 19%;
            left: 12%;
            animation: animationFramesTwo 30s alternate infinite linear;
  -webkit-animation: animationFramesTwo 30s alternate infinite linear;
        }
        &--2 {
            bottom: 0;
            left: 35%;
            animation: animationFramesThree 30s alternate infinite linear;
            -webkit-animation: animationFramesThree 30s alternate infinite linear;
        }
        &--3 {
            top: 25%;
            right: 30%;
            animation: animationFramesOne 30s alternate infinite linear;
            -webkit-animation: animationFramesOne 30s alternate infinite linear;
        }
        &--4 {
            bottom: 0;
            right: 10%;
            animation: animationFramesFive 30s alternate infinite linear;
            -webkit-animation: animationFramesFive 30s alternate infinite linear;
        }
    }
}
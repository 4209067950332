/*----------------------------------------*/
/*  14. newsletter
/*----------------------------------------*/
.xb-newsletter {
    padding: 70px;
    border: 1px solid #EDF3F5;
    box-shadow: 0px 14px 19px rgba(221, 229, 236, 0.42);
    @include border-radius(20px);
    overflow: hidden;
    margin-bottom: -165px;
    background-color: var(--color-white);
    @include respond(lg) {
        padding: 70px 50px;
    }
    @include respond(xs) {
        padding: 40px 30px;
    }
    .xb-item {
        &--holder {
            span {
                color: #787B84;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: -0.12px;
                text-transform: uppercase;
                display: inline-block;
                margin-bottom: 9px;
                line-height: 1;
            }
            h3 {
                font-size: 24px;
                font-weight: 600;
                letter-spacing: -0.72px;
            }
        }
        &--form {
            position: relative;
            input {
                height: 70px;
                width: calc(100% - 188px);
                background-color: #F4F8FA;
                padding: 20px;
                @include border-radius(10px);
                @include placeholder(#787B84);
                @include respond(xs) {
                    width: 100%;
                }
            }
            button {
                position: absolute;
                top: 0;
                right: 0;
                padding: 20px 50px;
                height: 70px;
                font-size: 16px;
                font-weight: 600;
                @include border-radius(10px);
                @include respond(xs) {
                    position: unset;
                    margin-top: 10px;
                    width: 100%;
                }
            }
        }
    }
    &__img {
        position: absolute;
        top: 0;
        right: 0;
        @include respond(md) {
            display: none;
        }
    }
}
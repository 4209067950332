/*----------------------------------------*/
/*  26. destination
/*----------------------------------------*/
.destination-section {
    padding-left: 20px;
    padding-right: 20px;
    @include respond(md) {
        padding-top: 50px;
    }
}
.xb-destination {
    border: 5px solid #fff;
    @include border-radius(20px);
    position: relative;
    overflow: hidden;
    box-shadow: 0px 14px 19px 0px rgba(221, 229, 236, 0.42);
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 43%);
        background: linear-gradient(0deg, #0F172A 3.77%, rgba(15, 23, 42, 0.00) 77.22%);
        content: "";
    }
    &.style-video {
        @include border-radius(50%);
        &::before {
            display: none;
        }
        
        .xb-item--img {
            cursor: pointer;
        }
    }
    .xb-item {
        &--holder {
            position: absolute;
            bottom: 45px;
            left: 40px;
            @media only screen and (min-width: 1200px) and (max-width: 1300px) {
                bottom: 30px;
                left: 20px;
            }
            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: -0.14px;
                color: #BDBDBD;
            }
        }
        &--title {
            color: var(--color-white);
            font-size: 20px;
            font-weight: 600;
            line-height: 34px;
            letter-spacing: -0.6px;
        }
        &--play {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            color: var(--color-white);
            font-size: 40px;
        }
    }
    &__strock-text {
        top: 50%;
        left: 0;
        @include transform(translateY(-50%));
        margin-left: -10%;
        margin-right: -10%;
    }
}
.row.gap-40 > * {
	padding-left: 20px;
    padding-right: 20px;
}
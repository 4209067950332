/*----------------------------------------*/
/*  15. contact
/*----------------------------------------*/
.contact-from {
    .xb-item {
        &--field {
            margin-bottom: 20px;
            position: relative;

            &>span,
            .icon {
                position: absolute;
                top: 19px;
                left: 20px;
                display: inline-block;
                line-height: 1;
                z-index: 1;
            }

            input,
            select,
            textarea {
                padding: 15px 46px 18px;
                border: 1px solid #EDF3F5;
                @include border-radius(10px);
                height: 60px;
                font-size: 14px;
                @include placeholder(#B1B4BA);
                @include transition(.3s);

                &:focus {
                    background-color: #F4F8FA;
                    box-shadow: none;
                }
            }

            textarea {
                min-height: 150px;
            }

            .errorMessage {
                color: red;
            }

            select {
                border-color: #EDF3F5;
                -webkit-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;
                -moz-appearance: none;
                background: #fff url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                cursor: pointer;
                padding-left: 46px;
                height: 60px;
                color: #B1B4BA;

            }

            .nice-select {
                border-color: #EDF3F5;
                margin-bottom: 20px;

                &:focus {
                    border-color: #EDF3F5;
                    background-color: #F4F8FA;
                }
            }
        }
    }
}

.xb-contact {
    @include border-radius(20px);
    background-color: var(--color-white);
    overflow: hidden;

    .xb-item {
        &--inner {
            padding: 75px;
            padding-right: 0;

            @include respond(lg) {
                padding: 50px;
                padding-right: 0;
            }

            @include respond(md) {
                padding: 30px;
            }
        }

        &--holder {
            span {
                font-size: 12px;
                text-transform: uppercase;
                color: var(--color-dark);
                display: inline-block;
                letter-spacing: -0.24px;
                margin-bottom: 20px;
                font-weight: 600;
                line-height: 1;
                display: flex;
                align-items: center;

                img {
                    margin-right: 9px;
                }
            }

            h3 {
                font-size: 24px;
                font-weight: 600;
                letter-spacing: -0.72px;

                @media(max-width:575px){
                    font-size: 23px;
                }
            }
        }
    }

    .google-map {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 45%;

        @include respond(md) {
            position: unset;
            height: 250px;
            width: 100%;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.contact-pt {
    padding-top: 256px;
}

.xb-contact2 {
    background-color: #121212;
    padding: 80px 110px;
    z-index: 1;
    position: relative;
    margin-bottom: -196px;

    @include respond(lg) {
        padding: 80px 50px;
    }

    @include respond(xs) {
        padding: 40px 20px;
    }

    &::before {
        @include before;
        width: 51%;
        left: auto;
        right: 0;
        background: linear-gradient(88deg, #121212 0%, rgba(18, 18, 18, 0.00) 100%);
        z-index: -1;
    }

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 59%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -2;

        @include respond(xs) {
            display: none;
        }
    }

    .xb-item {
        &--field {
            margin-bottom: 15px;
            position: relative;

            &>span {
                position: absolute;
                top: 19px;
                left: 20px;
                display: inline-block;
                line-height: 1;
                z-index: 1;
            }

            input,
            textarea {
                background-color: #252525;
                height: 60px;
                padding: 16px 52px 18px;
                font-size: 14px;
                color: var(--color-white);
                @include placeholder(#B1B4BA);
                @include transition(.3s);
                border: 0;
                box-shadow: none;
            }

            textarea {
                min-height: 160px;
            }

            .errorMessage {
                color: #D91334;
            }
        }

        &--holder {
            .sec-title--heading {
                font-size: 24px;
                line-height: 34px;
                letter-spacing: -0.72px;
            }
        }
    }
}

.contact-shape {
    @include respond(xs) {
        display: none;
    }

    .shape {
        position: absolute;

        &--1 {
            left: 4%;
            bottom: 6%;
        }

        &--2 {
            top: 12%;
            right: 33%;
        }

        &--3 {
            top: 38%;
            right: 18%;
        }

        &--4 {
            bottom: 6%;
            right: 38%;
        }
    }
}